import './src/styles/global.css'

export function shouldUpdateScroll(prevRouterProps, { location }) {
  console.log(prevRouterProps.routerProps.location.hash);
  if (prevRouterProps.routerProps && prevRouterProps.routerProps.location && prevRouterProps.prevRouterProps && prevRouterProps.prevRouterProps.location && prevRouterProps.routerProps.location.pathname !== prevRouterProps.prevRouterProps.location.pathname) {
    const html = document.getElementsByTagName('html')[0]

    html.classList.add('transitioning')

    let windowScrollTimeout = setTimeout(() => {
      window.scrollTo(0, 0)
      const body = document.getElementsByTagName('body')[0]
      body.scrollTop = 0
      html.classList.remove('transitioning')

      if (prevRouterProps.routerProps.location.hash) {
        const hashEl = document.querySelector(prevRouterProps.routerProps.location.hash);

        if (hashEl) {
          hashEl.scrollIntoView();
        }
      }
      clearTimeout(windowScrollTimeout)
    }, 5)
  } else if (prevRouterProps.routerProps && prevRouterProps.routerProps.location) {
    let windowScrollTimeout = setTimeout(() => {
      if (prevRouterProps.routerProps.location.hash) {
        const hashEl = document.querySelector(prevRouterProps.routerProps.location.hash);

        if (hashEl) {
          hashEl.scrollIntoView();
        }
      }
      clearTimeout(windowScrollTimeout)
    }, 5)
  }
  return false
}
