exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-lopd-js": () => import("./../../../src/pages/about-lopd.js" /* webpackChunkName: "component---src-pages-about-lopd-js" */),
  "component---src-pages-clinical-trial-results-js": () => import("./../../../src/pages/clinical-trial-results.js" /* webpackChunkName: "component---src-pages-clinical-trial-results-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-how-to-take-js": () => import("./../../../src/pages/how-to-take.js" /* webpackChunkName: "component---src-pages-how-to-take-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-and-tools-js": () => import("./../../../src/pages/support-and-tools.js" /* webpackChunkName: "component---src-pages-support-and-tools-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

